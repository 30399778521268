const Girl =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732561379/Girl_g85btn.jpg";
const sigil =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732561435/sigil_gvjctw.jpg";
const charlyvirtual =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732561357/charlybgoodvirtual_jlljwk.png";
const marilyn =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732561425/MARILYN_xam8a6.jpg";
const armani =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732561345/armani_zpztef.png";
const VanGhog =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732561450/vangogh_ws9k57.png";
const horsey =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732561394/horsey_fzsowa.png";
const monstruitoColor =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732563277/giphy_ybue3a.webp";
const rubikbutterflies =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732563494/rubikbutterflies_et8zsv.webp";
const charlybgoodneon =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732564655/charlybgoodneon_anept9.webp";
const virtualcrazycharly =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732564843/virtualcrazycharly_wno07t.webp";
const charlybgoodmundo =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732565548/charlybgoodmundo_yc2mif.webp";
const moonman =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732566801/moonman_ki3z5t.webp";
const corazono =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732566866/corazono_sepmxp.webp";
const cielobajoelagua =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732567017/cielobajoelagua_rzqzkn.webp";
const montruolunaysol =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732567128/monstruolunaysol_sjxkqs.webp";
const dancingLilith =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732567676/dancinglilith_cboull.webp";
const anonymousbitcoin =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732568236/anonymousbitcoin_ibyul5.webp";
const shivamaria =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732568456/shivamaria_eoxwbm.webp";
const dacningdiscoparty =
  "https://res.cloudinary.com/dr8pwzxzn/image/upload/v1732568573/dacningdiscoparty_hc5y83.webp";

export const srcImgs = [
  charlybgoodneon,
  moonman,
  Girl,
  corazono,
  cielobajoelagua,
  marilyn,
  charlybgoodmundo,
  virtualcrazycharly,
  charlyvirtual,
  VanGhog,
  montruolunaysol,
  monstruitoColor,
  dancingLilith,
  anonymousbitcoin,
  shivamaria,
  rubikbutterflies,
  dacningdiscoparty,
  horsey,
  sigil,
  armani,
];
