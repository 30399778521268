// import { useState } from "react";
import "../stylesheets/Contact.css";
import {
  FaGithub,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaVimeo,
  FaSoundcloud,
  FaBandcamp,
  FaSalesforce,
} from "react-icons/fa";

function Contact() {
  return (
    <div className="smedia-contact">
      <a
        href="https://github.com/CharlyBGood"
        title="github"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
        GitHub
      </a>
      <a
        href="https://bit.ly/BGoodYouTube"
        title="youtube"
        target="_blank"
        rel="noreferrer"
      >
        <FaYoutube />
        YouTube
      </a>
      <a
        href="https://vimeo.com/charlybgood"
        title="vimeo"
        target="_blank"
        rel="noreferrer"
      >
        <FaVimeo />
        Vimeo
      </a>
      <a
        href="https://www.instagram.com/charlsbgood/"
        title="instagram"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram />
        Instagram
      </a>
      <a
        href="https://twitter.com/CharlybGoodM"
        title="twitter"
        target="_blank"
        rel="noreferrer"
      >
        <FaTwitter />
        Twitter
      </a>
      <a
        href="https://soundcloud.com/charly-bgood"
        title="soundcloud"
        target="_blank"
        rel="noreferrer"
      >
        <FaSoundcloud />
        SoundCloud
      </a>
      <a
        href="https://charlybgood1.bandcamp.com/"
        title="bandcamp"
        target="_blank"
        rel="noreferrer"
      >
        <FaBandcamp />
        BandCamp
      </a>
      <a
        href="https://www.salesforce.com/trailblazer/carlosbonavita"
        title="trailhead"
        target="_blank"
        rel="noreferrer"
      >
        <FaSalesforce />
        TrailHead
      </a>
    </div>
  );
}

export default Contact;
