import { NavLink } from "react-router-dom";
import "../stylesheets/Navbar.css";
import Logo from "../img/img-headerlogo.png";
import { useState } from "react";
import { ContactModal } from "./social_media/ContactModal";
import { ImAddressBook } from "react-icons/im";


function Navbar() {
  const [isModalHidden, setIsModalHidden] = useState(true);

  const openLinks = () => {
    setIsModalHidden(false);
  };

  const closeModal = () => {
    setIsModalHidden(true);
  };

  return (
    <nav className="topnav" id="nav-bar">
      <NavLink className="nav-link_1" to="/">
        <img className="header-logo" src={Logo} alt="charly bgood logo" />
      </NavLink>
      <div className="social_media">
        <div className="nav-link_2" onClick={openLinks}>
          <ImAddressBook />
        </div>
      </div>
      <ContactModal
        closeModal={closeModal}
        isHidden={isModalHidden}
      ></ContactModal>      
    </nav>
  );
}

export default Navbar;
