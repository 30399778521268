import { NavLink } from "react-router-dom";
import ImgCenter from "../img/img-planetavacio.png";
import { FaCode, FaFolderOpen, FaPaintRoller } from "react-icons/fa";
import "../stylesheets/Content.css";

function Content() {
  return (
    <div className="img_container">
      <div className="decoration_words">
        <NavLink className="item item_1" to="/DevHome">
          <FaFolderOpen />
          _/dev_Home;
          <FaCode />
        </NavLink>
        <NavLink className="item item_2" to="./Music">
          <FaFolderOpen />
          _&#119070;mU§iç♫
        </NavLink>
      </div>

      <img
        className="img"
        id="header-img"
        src={ImgCenter}
        alt="Charly BGood Logo"
      />

      <div className="decoration_words">
        <NavLink className="item item_3" to="/VisualArt">
          <FaFolderOpen />
          _Vi$u☆L_☆rt <FaPaintRoller />
        </NavLink>
      </div>
    </div>
  );
}

export default Content;
