import React from "react";
import "../stylesheets/Footer.css";

function Footer() {
  return (
    <footer className="smedia">
      <p>
        ~{" "}
        <a
          href="https://github.com/CharlyBGood"
          target="_blank"
          rel="noreferrer"
        >
          by Charly BGood
        </a>
        {" "}~
      </p>
    </footer>
  );
}

export default Footer;
