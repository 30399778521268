import { NavLink } from "react-router-dom";
import { FaFileCode, FaPaintRoller } from "react-icons/fa";
import DevProjects from "./DevProjects";
import { AiOutlineArrowLeft } from "react-icons/ai";

function SinglePages() {
  return (
    <>
      <p className="p_description">
        <FaPaintRoller /> Web Pages <FaFileCode />
      </p>
      <div className="img_container">
        <div className="decoration_words">
          <DevProjects
            className="item item_1"
            projectLink="https://www.reinamomo.art"
            projectName="#reinaMomoArteSana"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://charlybgood.github.io/freecodecamp/webdesign/2-surveyform/index.html"
            projectName="#surveyForm"
          />
          <DevProjects
            className="item item_1"
            projectLink="https://charlybgood.github.io/freecodecamp/webdesign/3-productlandingpage/index.html"
            projectName="#landingPage"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://estarsiempreseguros.com.ar/"
            projectName="#estarSiempreSeguros"
          />          
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/DevHome">
          <AiOutlineArrowLeft />
        </NavLink>
      </button>
    </>
  );
}

export default SinglePages;
