function DevProjects(props) {
  return (
    <>
      <a
        className={props.className}
        href={props.projectLink}
        target="_blank"
        rel="noreferrer"
      >
        <code>{props.projectName}</code>
      </a>
    </>
  );
}

export default DevProjects;
