import { FaRegEyeSlash } from "react-icons/fa";
import Contact from "../Contact";

export function ContactModal({ isHidden, closeModal }) {
  return (
    <div className={isHidden ? "img-modal" : "img-modal-open"}>
      <button className="close-modal" onClick={closeModal}>
        <FaRegEyeSlash />
      </button>
      <Contact></Contact>
    </div>
  );
}
