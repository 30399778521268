import { useEffect, useState } from "react";
import "../stylesheets/VisualArt.css";
import { Image } from "../components/visual_art/Image";
import { srcImgs } from "../data/imgData";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "./visual_art/Modal";
import { NavLink } from "react-router-dom";
import { FaPaintRoller } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";

function VisualArt() {
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalHidden, setIsModalHidden] = useState(true);
  const navigate = useNavigate();
  const { imageId } = useParams();

  useEffect(() => {
    if (imageId) {
      const imgIndex = parseInt(imageId, 10);
      setImageSrc(srcImgs[imgIndex]);
      setIsModalHidden(false);
    } else {
      setIsModalHidden(true);
    }
  }, [imageId]);

  const openImg = (e) => {
    const imgIndex = srcImgs.indexOf(e.target.src);
    setImageSrc(e.target.src);
    setIsModalHidden(false);
    navigate(`/VisualArt/${imgIndex}`);
  };

  const closeImg = (e) => {
    setIsModalHidden(true);
    navigate("/VisualArt");
  };

  const currentIndex = srcImgs.findIndex((img) => img === imageSrc);

  const prev = () => {
    const prevIndex = (currentIndex - 1 + srcImgs.length) % srcImgs.length;
    setImageSrc(srcImgs[prevIndex]);
    navigate(`/VisualArt/${prevIndex}`);
  };

  const next = () => {
    const nextIndex = (currentIndex + 1) % srcImgs.length;
    setImageSrc(srcImgs[nextIndex]);
    navigate(`/VisualArt/${nextIndex}`);
  };

  return (
    <div className="main-gy-container">
      <>
        <h3 className="gallery-title">
          _Vi$u☆L_☆rt <FaPaintRoller />
        </h3>
      </>
      <div className="gy-container">
        {srcImgs.map((img, index) => {
          return <Image key={index} src={img} openImg={openImg} />;
        })}
      </div>
      <Modal
        isHidden={isModalHidden}
        imageSrc={imageSrc}
        closeImg={closeImg}
        handlePrevious={prev}
        handleNext={next}
      />
      <button className="btn-back">
        <NavLink to="/">
          <AiOutlineArrowLeft />
        </NavLink>
      </button>
    </div>
  );
}

export default VisualArt;
